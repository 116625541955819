const mapSteps = {
  0: "",
  1: "step1",
  2: "step2",
  3: "step3",
  4: "step4",
  5: "step5",
  6: "step6",
  7: "step7",
  8: "step8",
}

export default mapSteps

import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  questions: [],
  filteredQuestions: [],
  categories: [],
}

export const faqsSlice = createSlice({
  name: "faqs",
  initialState,
  reducers: {
    setQuestions: (state, action) => {
      state.questions = action.payload
    },
    setFilteredQuestions: (state, action) => {
      state.filteredQuestions = action.payload
    },
    setFaqCategories: (state, action) => {
      state.categories = action.payload
    },
  },
})

export const { setQuestions, setFilteredQuestions, setFaqCategories } = faqsSlice.actions

export default faqsSlice.reducer

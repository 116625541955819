import { createSlice, current, createSelector } from "@reduxjs/toolkit"
import dayjs from "dayjs"

const parseDate = new Date()
const currentParseDateForStore = dayjs(parseDate).format("YYYY-MM-DD")

export const pharmacyBookFlowInitialState = {
  step: 1,
  canContinue: false,
  categories: null,
  services: null,
  //step-1
  selectedCategory: null,
  selectedService: null,
  //step-2
  dateAndTimeData: {
    startDate: currentParseDateForStore,
    selectedHour: null,
    slots: null,
    isSlotStillAvailable: true,
    slotsForNextSevenDays: null,
    //dateRange,
  },
  //addon
  addonData: {
    addons: null,
    selectedAddon: null,
  },
  //step-3
  userData: {
    tryToSubmit: false,
    firstName: "",
    lastName: "",
    email: "",
    date: "",
    phone: "",
    userAreCreated: false,
    userBookingProfile: null,
    bookingId: null,
    userConfirmedCode: null,
  },
  //checkout card
  bookingState: {
    category: null,
    service: null,
    userDateAndTime: null,
    possibleAddon: false,
    addon: null,
    userInfo: null,
    paymentInfo: null,
    providerIds: null,
  },
  isLoading: false,
  isError: null,
}

export const pharmacyBookFlowSlice = createSlice({
  name: "pharmacyBookFlow",
  initialState: pharmacyBookFlowInitialState,
  reducers: {
    setStep: (state, { payload }) => {
      state.step = payload
    },
    setCanContinue: (state, { payload }) => {
      if (state.canContinue === payload) return
      state.canContinue = payload
    },
    setCategories: (state, { payload }) => {
      state.categories = payload
    },
    setSelectedCategory: (state, { payload }) => {
      state.selectedCategory = payload
    },
    setSelectedService: (state, { payload }) => {
      state.selectedService = payload
    },
    setServices: (state, { payload }) => {
      state.services = payload
    },
    updateBookingState: (state, { payload }) => {
      state.bookingState = {
        ...state.bookingState,
        ...payload.updateValues,
        //clear fields is when user step back and change category or service
        // ...payload.clearFields,
      }
    },
    updateTimeAndDateData: (state, { payload }) => {
      state.dateAndTimeData = {
        ...state.dateAndTimeData,
        ...payload.updateValues,
      }
    },
    updateAddonData: (state, { payload }) => {
      state.addonData = payload
    },
    updateUserData: (state, { payload }) => {
      if (payload.tryToSubmit) {
        state.userData = {
          ...state.userData,
          tryToSubmit: true,
        }
        return
      }

      state.userData = {
        ...state.userData,
        ...payload,
      }
    },
    setIsLoading: (state, { payload }) => {
      state.isLoading = payload
    },
    clearStateAfterCategoryChange: state => {
      const chosenCategory = current(state).bookingState.category || null

      const currentBookingState = {
        category: chosenCategory,
        service: null,
        userDateAndTime: null,
        possibleAddon: false,
        addon: null,
      }

      return {
        ...state,
        step: 1,
        canContinue: false,
        isLoading: false,
        selectedService: null,
        dateAndTimeData: {
          ...pharmacyBookFlowInitialState.dateAndTimeData,
        },
        addonData: {
          ...pharmacyBookFlowInitialState.addonData,
        },
        userData: {
          ...pharmacyBookFlowInitialState.userData,
        },
        bookingState: {
          ...currentBookingState,
        },
        isError: null,
      }
    },
    setIsError: (state, { payload }) => {
      state.isError = payload
    },
    resetToInitialState: state => {
      state = Object.assign(state, pharmacyBookFlowInitialState)
    },
  },
})

const dateAndTimeData = state => state?.pharmacyBookFlow?.dateAndTimeData
const selectedService = state => state?.pharmacyBookFlow?.selectedService
const addonData = state => state?.pharmacyBookFlow?.addonData
const userData = state => state?.pharmacyBookFlow?.userData
const category = state => state?.pharmacyBookFlow?.selectedCategory

export const selectCombinedState = createSelector(
  dateAndTimeData,
  selectedService,
  addonData,
  userData,
  category,
  (dateAndTime, service, addon, user, category) => ({
    dateAndTime,
    service,
    addon,
    user,
    category,
  })
)

export const {
  setStep,
  setCanContinue,
  setCategories,
  setSelectedCategory,
  setSelectedService,
  setServices,
  updateBookingState,
  updateTimeAndDateData,
  updateAddonData,
  setIsLoading,
  clearStateAfterCategoryChange,
  updateUserData,
  setIsError,
  resetToInitialState,
} = pharmacyBookFlowSlice.actions

export default pharmacyBookFlowSlice.reducer

import { isAnyOf } from "@reduxjs/toolkit"

import {
  clearStateAfterCategoryChange,
  setStep as pharmacySetStep,
  resetToInitialState,
  setCategories,
  setIsLoading,
  setSelectedCategory,
  setSelectedService,
  updateAddonData,
  updateBookingState,
  updateTimeAndDateData,
  updateUserData,
} from "../features/pharmacyBookFlow/pharmacyBookFlow"

import { createListenerMiddleware } from "@reduxjs/toolkit"

// const isBrowser = typeof window !== "undefined"

export const pharmacyBookFlowMiddleWare = createListenerMiddleware()
pharmacyBookFlowMiddleWare.startListening({
  matcher: isAnyOf(
    pharmacySetStep,
    setCategories,
    updateBookingState,
    updateTimeAndDateData,
    setSelectedCategory,
    setSelectedService,
    updateAddonData,
    clearStateAfterCategoryChange,
    updateUserData,
    setIsLoading,
    resetToInitialState
  ),
  effect: (action, listenerApi) => {
    if (!action?.type !== "/resetToInitialState") {
      const currentState = listenerApi.getState().pharmacyBookFlow
      window.localStorage.setItem(
        "pharmacyBookFlow",
        JSON.stringify(currentState)
      )
    } else if (!action?.type === "/resetToInitialState") {
      window.localStorage.removeItem("pharmacyBookFlow")
    }
  },
})

import { configureStore, isAnyOf } from "@reduxjs/toolkit"
import specialistsReducer from "./features/specialists/specialistsSlice"
import articlesReducer from "./features/articles/articlesSlice"
import faqsReducer from "./features/faqs/faqsSlice"
import servicesReducer from "./features/services/servicesSlice"
import rootReducer from "./features/root/rootSlice"
import mapSteps from "./utils/mapSteps"
import { navigate } from "gatsby"

import bookFlowReducer, {
  bookFlowInitialState,
  resetToInitialState,
  setCheckoutValuesChanges,
  setStep,
} from "./features/bookFlow/bookFlowSlice"

import pharmacyBookFlowReducer, {
  pharmacyBookFlowInitialState,
} from "./features/pharmacyBookFlow/pharmacyBookFlow"

import { pharmacyBookFlowMiddleWare } from "./middlewares/pharmacyBookingMiddlaware"

import { createListenerMiddleware } from "@reduxjs/toolkit"

const isBrowser = typeof window !== "undefined"

//BOOKFLOW Move to middleware folder

export const bookFlowMiddleware = createListenerMiddleware()

bookFlowMiddleware.startListening({
  matcher: isAnyOf(setStep, resetToInitialState, setCheckoutValuesChanges),
  effect: (action, listenerApi) => {
    if (
      action.type.includes("/setStep") ||
      action.type === "bookFlow/setCheckoutValuesChanges"
    ) {
      const currentState = listenerApi.getState().bookFlow
      let newState = {
        ...currentState,
        categoryAvailabilities: [],
      }
      window.localStorage.setItem("bookFlowState", JSON.stringify(newState))
    } else if (action.type.includes("/resetToInitialState")) {
      window.localStorage.removeItem("bookFlowState")
    }

    if (action.type === "bookFlow/setStep") {
      const url = `/book-an-appointment/${mapSteps[action.payload]}`
      navigate(url)
    }
  },
})

let bookFlowLocaleStoreState = null
let bookAppointmentInitialPage = false

if (isBrowser) {
  bookFlowLocaleStoreState = JSON.parse(
    window.localStorage.getItem("bookFlowState")
  )
  if (window?.location?.pathname.includes("book-an-appointment")) {
    bookAppointmentInitialPage = true
  }
}

let phramacyBookFlowLocaleStoreState = null
let pharmacyPageInitialPage = false

if (isBrowser) {
  phramacyBookFlowLocaleStoreState = JSON.parse(
    window.localStorage.getItem("pharmacyBookFlow")
  )
  if (window?.location?.pathname.includes("booking-pharmacy")) {
    pharmacyPageInitialPage = true
  }
}

export const store = configureStore({
  preloadedState: {
    bookFlow:
      bookFlowLocaleStoreState !== null && bookAppointmentInitialPage
        ? bookFlowLocaleStoreState
        : bookFlowInitialState,
    pharmacyBookFlow:
      phramacyBookFlowLocaleStoreState !== null && pharmacyPageInitialPage
        ? phramacyBookFlowLocaleStoreState
        : pharmacyBookFlowInitialState,
  },
  reducer: {
    specialists: specialistsReducer,
    articles: articlesReducer,
    faqs: faqsReducer,
    services: servicesReducer,
    bookFlow: bookFlowReducer,
    pharmacyBookFlow: pharmacyBookFlowReducer,
    root: rootReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware()
      .concat(bookFlowMiddleware.middleware)
      .concat(pharmacyBookFlowMiddleWare.middleware),
})

if (process.env.NODE_ENV === "development") {
  if (typeof window !== "undefined") {
    window.store = store
  }
}

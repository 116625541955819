import { createSlice, current } from "@reduxjs/toolkit"

const initialState = {
  value: [],
  categories: [],
  filteredValue: [],
}

export const articlesSlice = createSlice({
  name: "articles",
  initialState,
  reducers: {
    setArticles: (state, action) => {
      state.value = action.payload
      state.filteredValue = action.payload
    },
    getArticlesByCategory: (state, action) => {
      if (action.payload === null) {
        state.filteredValue = state.value
        return
      }
      state.filteredValue = current(state.value).filter(article =>
        article.categories.filter(category => category.title === action.payload)
          .length > 0
          ? true
          : false
      )
    },
  },
})

export const { setArticles, getArticlesByCategory } = articlesSlice.actions

export default articlesSlice.reducer

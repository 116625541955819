import { createSlice, current } from "@reduxjs/toolkit"

const initialState = {
  value: [],
  filteredValue: [],
  filters: [],
  selectedFilters: [],
  conditions: [],
  procedures: [],
  specialties: [],
  filteredConditions: [],
  filteredProcedures: [],
  filteredSpecialties: [],
  specialtiesPages: [],
  enableSlider: false,
}

export const specialistsSlice = createSlice({
  name: "specialists",
  initialState,
  reducers: {
    setSpecialists: (state, action) => {
      state.value = action.payload
      state.filteredValue = action.payload
    },
    setFilters: (state, action) => {
      state.filters = action.payload
    },
    setSelectedFilters: (state, action) => {
      if (action.payload.redirect) {
        const tempArray = state.filters[0].values.map(filter => {
          return { ...filter, value: filter.title === action.payload.id }
        })
        state.filters[0].values = tempArray
        state.selectedFilters = [
          {
            filter: action.payload.filter,
            val: true,
            id: action.payload.id,
          },
        ]
      } else {
        state.filters.forEach(element => {
          if (element.title === action.payload.filter) {
            element.values.forEach(el => {
              if (el.title === action.payload.id) {
                el.value = action.payload.val
              } else {
                if (element.title === "Sort Filter") {
                  el.value = false
                }
              }
            })
          }
        })
        if (action.payload.val === true) {
          if (
            action.payload.filter === "Sort Filter" &&
            current(state.selectedFilters).find(
              el => el.filter === "Sort Filter"
            )
          ) {
            let index = current(state.selectedFilters).findIndex(
              el => el.filter === "Sort Filter"
            )
            let arr = [...current(state.selectedFilters)]
            arr.splice(index, 1)
            state.selectedFilters = [...arr]
          }
          state.selectedFilters.push(action.payload)
        } else {
          let index = current(state.selectedFilters).findIndex(
            item => item.id === action.payload.id
          )
          let arr = [...current(state.selectedFilters)]
          arr.splice(index, 1)
          state.selectedFilters = [...arr]
        }
      }
    },
    setFilteredSpecialists: (state, payload) => {
      if (current(state.selectedFilters).length > 0) {
        state.filteredValue = current(state.value)
          .filter(specialist => {
            const filters = current(state.selectedFilters).filter(
              filter => filter.filter === "Location Filter"
            )
            if (!filters?.length) return true
            const isPrimaryLocation = specialist?.primaryLocations?.some(el =>
              filters.find(filter => el?.title === filter?.id)
            )
            const isPrivateLocation = specialist?.privateLocations?.some(el =>
              filters.find(filter => el?.title === filter?.id)
            )
            return isPrimaryLocation || isPrivateLocation
          })
          .filter(specialist => {
            const filters = current(state.selectedFilters).filter(
              filter => filter.filter === "Specialty Filter"
            )
            if (!filters?.length) return true
            return specialist?.specialties?.some(el =>
              filters.find(filter => el?.title === filter?.id)
            )
          })
          .filter(specialist => {
            const filters = current(state.selectedFilters).filter(
              filter => filter.filter === "Type of doctor Filter"
            )
            if (!filters?.length) return true
            return specialist?.typeOfDoctor?.some(el =>
              filters.find(filter => el?.title === filter?.id)
            )
          })
          .filter(specialist => {
            const filters = current(state.selectedFilters).filter(
              filter => filter.filter === "Gender Filter"
            )
            if (!filters?.length) return true
            const isGender = filters.find(
              filter => specialist?.gender?.title === filter.id
            )
            return specialist?.gender?.title === isGender?.id
          })
          .filter(specialist => {
            const filters = current(state.selectedFilters).filter(
              filter => filter.filter === "Language Filter"
            )
            if (!filters?.length) return true
            return specialist.languages.some(el =>
              filters.find(filter => el.title === filter.id)
            )
          })

        const sortFilter = current(state.selectedFilters).find(
          filter => filter.filter === "Sort Filter"
        )
        if (sortFilter) {
          switch (sortFilter.id) {
            case "Name A - Z":
              state.filteredValue.sort((a, b) => {
                const arrayA = a.title.trim().split(" ")
                const doctorA = arrayA[arrayA.length - 1]

                const arrayB = b.title.trim().split(" ")
                const doctorB = arrayB[arrayB.length - 1]

                return doctorB > doctorA ? -1 : 1
              })
              break
            case "Name Z - A":
              state.filteredValue.sort((a, b) => {
                const arrayA = a.title.trim().split(" ")
                const doctorA = arrayA[arrayA.length - 1]

                const arrayB = b.title.trim().split(" ")
                const doctorB = arrayB[arrayB.length - 1]

                return doctorB > doctorA ? 1 : -1
              })
              break
            case "Rating":
              state.filteredValue.sort((a, b) => {
                let doctorB = b.rating.includes("--") ? 0 : b.rating
                let doctorA = a.rating.includes("--") ? 0 : a.rating
                return doctorB - doctorA
              })
              break
            case "Patients Seen - Descending":
              state.filteredValue.sort((a, b) => {
                let doctorB = b.patientsSeen.includes("--") ? 0 : b.patientsSeen
                let doctorA = a.patientsSeen.includes("--") ? 0 : a.patientsSeen
                return doctorB - doctorA
              })
              break
            default:
              state.filteredValue.sort((a, b) =>
                a.title.split(" ").slice(1) < b.title.split(" ").slice(1)
                  ? -1
                  : 1
              )
              break
          }
        }
      } else {
        state.filteredValue = state.value
      }
    },
    resetSelectedFilters: (state, { payload }) => {
      // if (payload) {
      //   state.selectedFilters = []

      //   state.filters.forEach(element => {
      //     element.values.forEach(el => {
      //       el.value = false
      //     })
      //   })
      //   return
      // }
      state.selectedFilters = []
      state.filteredValue = [...current(state.value)]
      state.filters.forEach(element => {
        element.values.forEach(el => {
          el.value = false
        })
      })
    },
    setConditions: (state, action) => {
      state.conditions = action.payload
      state.filteredConditions = action.payload
    },
    setProcedures: (state, action) => {
      state.procedures = action.payload
      state.filteredProcedures = action.payload
    },
    setSpecialties: (state, action) => {
      state.specialties = action.payload.find(
        el => el.title === "Specialty Filter"
      ).values
      state.filteredSpecialties = action.payload.find(
        el => el.title === "Specialty Filter"
      ).values
    },
    filterDataFromSearch: (state, action) => {
      // state.filteredValue = []
      // state.filteredConditions = []
      // state.filteredProcedures = []
      // state.filteredSpecialties = []
      if (state.selectedFilters.length === 0) {
        state.filteredValue = current(state.value).filter(el => {
          return el.fullName
            .toLowerCase()
            .includes(action.payload.toLowerCase())
        })
      } else {
        state.filteredValue = current(state.filteredValue).filter(el => {
          return el.fullName
            .toLowerCase()
            .includes(action.payload.toLowerCase())
        })
      }
      // state.filteredConditions = current(state.conditions).filter(el => {
      //   return el.title.toLowerCase().includes(action.payload.toLowerCase())
      // })
      // state.filteredProcedures = current(state.procedures).filter(el => {
      //   return el.title.toLowerCase().includes(action.payload.toLowerCase())
      // })
      // state.filteredSpecialties = current(state.filteredSpecialties).filter(el => { //change here
      //   return el.title.toLowerCase().includes(action.payload.toLowerCase())
      // })
    },
    setSpecialtiesPages: (state, { payload }) => {
      state.specialtiesPages = payload
    },
    setEnableSlider: state => {
      state.enableSlider(true)
    },
  },
})

export const {
  setSpecialists,
  setFilters,
  setSelectedFilters,
  resetSelectedFilters,
  setFilteredSpecialists,
  setConditions,
  setProcedures,
  setSpecialties,
  filterDataFromSearch,
  setSpecialtiesPages,
} = specialistsSlice.actions

export default specialistsSlice.reducer

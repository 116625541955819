import { createSlice, current } from "@reduxjs/toolkit"

const initialState = {
  value: [],
  filteredValue: [],
  categories: [],
}

export const servicesSlice = createSlice({
  name: "services",
  initialState,
  reducers: {
    setServices: (state, action) => {
      state.value = action.payload
      state.filteredValue = action.payload
    },
    setServiceCategories: (state, action) => {
      state.categories = action.payload
    },
    setFilteredServices: (state, action) => {
      state.filteredValue = []
      state.filteredValue = current(state.value).filter(el => {
        return el.title.toLowerCase().includes(action.payload.toLowerCase())
      })
    },
  },
})

export const { setServices, setServiceCategories, setFilteredServices } =
  servicesSlice.actions

export default servicesSlice.reducer
